/** @format */

import { useEffect, useRef, useState } from "react";
import { DetailsPage } from "./DetailsPage/DetailsPage";
import { LiveMapContainer } from "./LiveMap/LiveMapContainer";
import { BidForm } from "./BidForm/BidForm";
import { useTelegram } from "../fns/useTelegram";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function AppWrapper() {
  const [panels, setPanels] = useState(0);
  const [bidData, setBidData] = useState(undefined);
  const [colapse, setColapse] = useState(false);

  const { tg } = useTelegram();
  let change_count = 0;

  Telegram.WebApp.onEvent("viewportChanged", (event) => {
    change_count = change_count + 1;
    let isStateStable = event.isStateStable;
    let currentHeight = window.innerHeight + "px";
    // alert(currentHeight);
    // let currentHeight = window.visualViewport.height+"px";
    // let currentHeight = window.Telegram.WebApp.viewportStableHeight+"px";

    root.style.setProperty("--tg-viewport-stable-height", currentHeight);
  });

  const switchViews = (data) => {
    setPanels(data);
  };

  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  const dataString = urlParams.get("tgWebAppStartParam");
  const data = JSON.parse(dataString);

  let order_id = data?.oid;

  if (!tg?.initDataUnsafe?.user?.id) {
    window.location.href = "https://hfield.net";
  }

  useEffect(() => {
    let ignore = false;

    const bidData = async () => {
      const data = await fetch(
        "https://platform.hfield.net/telegramwebapp/getbiddata",
        {
          method: "POST",
          mode: "cors",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            // Commented out for testing
            order_id,
            telegram_id: tg?.initDataUnsafe?.user?.id,
            // order_id: null,
            // telegram_id: 1154656217,
          }),
        }
      );

      return await data.json();
    };

    bidData()
      .then((res) => {
        if (!ignore) {
          setBidData(res);
        }
      })
      .catch((res) => {
        toast.error("Service Temporarily Unavailable");
      });
    return () => {
      ignore = true;
    };
  }, [order_id]);

  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
        <BidForm
          orderInfo={bidData}
          setColapse={setColapse}
          colapse={colapse}
        />
        <div
          style={{
            flex: 1,
            display: "flex",
          }}
        >
          {panels == 0 ? (
            <div
              style={{
                width: "100vw",
                height: "100%",
                flex: 1,
                animation: "fadeIn 1s ease-in-out",
                WebkitAnimation: "fadeIn 1s ease-in-out",
                MozAnimation: "fadeIn 1s ease-in-out",
                OAnimation: "fadeIn 1s ease-in-out",
                MsAnimation: "fadeIn 1s ease-in-out",
              }}
            >
              <LiveMapContainer orderInfo={bidData} switchViews={switchViews} />
            </div>
          ) : (
            <div
              style={{
                width: "100vw",
                height: "100%",
                animation: "fadeIn 1s ease-in-out",
                WebkitAnimation: "fadeIn 1s ease-in-out",
                MozAnimation: "fadeIn 1s ease-in-out",
                OAnimation: "fadeIn 1s ease-in-out",
                MsAnimation: "fadeIn 1s ease-in-out",
              }}
            >
              <DetailsPage orderInfo={bidData} switchViews={switchViews} />
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        toastStyle={{
          width: "70%",
          alignSelf: "flex-end",
          float: "right",
          marginTop: 10,
        }}
        autoClose={4000}
      />
      <div
        style={{
          position: "absolute",
          bottom: 10,
          right: 20,
          zIndex: 9999999,
        }}
      >
        v0.0.3
      </div>
    </div>
  );
}
