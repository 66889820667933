/** @format */

import { useEffect, useRef, useState } from "react";
import { useTelegram } from "../../fns/useTelegram";
import BarLoader from "react-spinners/BarLoader";
import { toast } from "react-toastify";

export function BidForm({ orderInfo, setColapse, colapse }) {
  const { initData, onClose } = useTelegram();
  const [calculated, setCalculated] = useState({ price: "", rpm: "" });
  const refPrice = useRef();
  const refRPM = useRef();
  const [loading, setLoading] = useState(false);

  const endPoint = "https://platform.hfield.net/telegramwebapp/setbiddata";

  const sendDataToTelegram = async () => {
    setLoading(true);

    try {
      let data = { ...initData, ...calculated };

      const numberRegex = /^[-+]?[0-9]+(?:\.[0-9]+)?$/;

      if (!data.price || !data.rpm) {
        ("Please enter price and rpm !");
        return;
      }

      if (!numberRegex.test(data.price)) {
        toast.warning("Please enter valid price is number !");
        return;
      }

      if (!numberRegex.test(data.rpm)) {
        toast.warning("Please enter valid rpm is number !");
        return;
      }

      data.price = parseFloat(data.price);

      data.rpm = parseFloat(data.rpm);

      if (data.isPriceCal) {
        if (data.price < 100 || data.price > 10000) {
          toast.warning("Please enter valid price between 100 and 10000 !");
          return;
        }
      } else {
        if (
          (data.rpm < 0.3 || data.rpm > 3) &&
          orderInfo?.order_data?.estimate_miles > 600
        ) {
          toast.warning("Please enter valid rpm between 0.3 and 3 !");
          return;
        }
      }

      delete data.isPriceCal;
      delete data.rpm;

      const response = await fetch(endPoint, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (result.success == true) {
        toast.success(result.message || "Sent price success !", {
          autoClose: 2000,
          onClose: () => {
            onClose();
          },
        });
      } else {
        toast.error(result.message || "Sent price failed !");
      }
    } catch (e) {
      toast.success("Sent price failed !");
      setLoading(false);
    } finally {
      setLoading(false);
    }

    setLoading(false);
  };

  const onChangePrice = (e) => {
    let price = e.target.value;

    price = (price + "").replace(/[,]/g, ".");

    if (!isNaN(price)) {
      const estimate_miles = orderInfo?.order_data?.estimate_miles;
      if (estimate_miles) {
        const rpm = parseFloat(price / estimate_miles).toFixed(2);
        refRPM.current.value = rpm;
        setCalculated({ price, rpm, isPriceCal: true });
      } else {
        setCalculated({ price: 0, rpm: 0 });
      }
    }
  };

  const onChangeRpm = (e) => {
    let rpm = e.target.value;

    rpm = (rpm + "").replace(/,/g, ".");

    if (!isNaN(rpm)) {
      const estimate_miles = orderInfo?.order_data?.estimate_miles;
      if (estimate_miles) {
        const price = parseFloat(rpm * estimate_miles).toFixed(0);
        refPrice.current.value = price;
        setCalculated({ price, rpm, isPriceCal: false });
      } else {
        setCalculated({ price: 0, rpm: 0 });
      }
    }
  };

  const handleFocus = (event) => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (isIOS) {
      if (colapse == false) {
        // let currentHeight = window.innerHeight+"px";
        // let currentHeight = window.visualViewport.height+"px";
        let currentHeight = window.Telegram.WebApp.viewportStableHeight + "px";
        root.style.setProperty("--tg-viewport-stable-height", currentHeight);

        // root.style.setProperty('--tg-viewport-stable-height', '60vh');
        // const viewportHeight = window.innerHeight;
        // root.style.setProperty('--tg-viewport-stable-height', (viewportHeight - 150) + 'px');
        // root.style.setProperty('--tg-viewport-stable-height', Telegram.WebApp.viewportHeight + 'px');
        setColapse(true);
      }
    }
  };

  const handleBlure = (event) => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (isIOS) {
      if (colapse == true) {
        setColapse(false);
      }
    }
  };

  useEffect;

  return (
    <div
      className="progress-block-bottom"
      style={{
        padding: 10,
      }}
    >
      <div className="submit-price-block">
        <div className="submit-price-block-inputs">
          <input
            type="number"
            placeholder="Total price"
            ref={refPrice}
            onFocus={handleFocus}
            value={calculated.price}
            onChange={(e) => {
              onChangePrice(e);
            }}
            style={{
              fontSize: "16px",
            }}
            min={100}
            max={10000}
          />
          OR
          <input
            type="number"
            ref={refRPM}
            placeholder="RPM"
            value={calculated.rpm}
            onChange={(e) => {
              onChangeRpm(e);
            }}
            style={{
              fontSize: "16px",
            }}
            min={0.3}
            max={3}
          />
        </div>
        <button
          id="bid-button"
          onClick={sendDataToTelegram}
          disabled={!calculated.price || !calculated.rpm}
          style={{
            opacity: calculated.price && calculated.rpm ? 1 : 0.3,
          }}
        >
          Make Offer
        </button>
        {loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999999,
              top: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            }}
          >
            <BarLoader color="#3290EC" size={20} />
          </div>
        )}
      </div>
    </div>
  );
}
