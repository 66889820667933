/** @format */

import DetailItem from "./DetailItem";
import map from "../../assets/map.png";
import location from "../../assets/location.svg";
import point from "../../assets/point.svg";
import truck from "../../assets/truck.svg";
import id from "../../assets/id.svg";
import driver from "../../assets/driver.svg";
import vin from "../../assets/vin.svg";

const formatDate = (dateString) => {
  const options = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "America/New_York",
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
};

export function DetailsPage({ orderInfo, switchViews }) {
  let pageData = orderInfo?.order_data;
  let truckData = orderInfo?.truck;

  return (
    <>
      <div
        className="progress-block-top"
        style={{
          flex: 1,
          flexBasis: 1,
          width: "100%",
          height: "100%",
          maxHeight: "calc(100vh - 140px)",
          overflowY: "scroll",
        }}
      >
        <div
          className="progress-block-info"
          id="details"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 10,
            flex: 1,
            flexBasis: 1,
          }}
        >
          <div
            style={{
              flex: 0.3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "3%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <img src={location} style={{}} />
                <img src={point} style={{}} />
              </div>
              <div
                style={{
                  flex: 1,
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    alignItems: "flex-start",
                    marginLeft: 10,
                  }}
                >
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: "0.875rem",
                    }}
                  >
                    {pageData?.pickup_loc}
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "0.8rem",
                      color: "#8A8A8A",
                    }}
                  >
                    {formatDate(pageData?.pickup_date) !== "Invalid Date"
                      ? formatDate(pageData?.pickup_date)
                      : "ASAP"}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                    marginLeft: 10,
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: "0.875rem",
                    }}
                  >
                    {pageData?.delivery_location}
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "0.8rem",
                      color: "#8A8A8A",
                    }}
                  >
                    {pageData?.delivery_date
                      ? formatDate(pageData?.delivery_date)
                      : ""}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexBasis: 1,
                  gap: "1%",
                  paddingInline: "3%",
                  marginTop: "1%",
                  overflowY: "scroll",
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgba(50, 144, 236, 0.05)",
                    borderRadius: 5,
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#3290EC",
                    }}
                  >
                    {pageData?.estimate_miles}
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 500,
                      marginTop: 5,
                      color: "rgba(0, 0, 0, 1)",
                    }}
                  >
                    Est. Miles
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "rgba(50, 144, 236, 0.05)",
                    borderRadius: 5,
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#3290EC",
                    }}
                  >
                    {pageData?.out_miles}
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 500,
                      marginTop: 5,
                      color: "rgba(0, 0, 0, 1)",
                    }}
                  >
                    Out Miles
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "rgba(50, 144, 236, 0.05)",
                    borderRadius: 5,
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#3290EC",
                    }}
                  >
                    {pageData?.pieces}
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 500,
                      marginTop: 5,
                      color: "rgba(0, 0, 0, 1)",
                    }}
                  >
                    Pieces
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "rgba(50, 144, 236, 0.05)",
                    borderRadius: 5,
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#3290EC",
                    }}
                  >
                    {pageData?.weight}
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 500,
                      marginTop: 5,
                      color: "rgba(0, 0, 0, 1)",
                    }}
                  >
                    Weight (lbs)
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexBasis: 1,
                  gap: "1%",
                  paddingInline: "3%",
                  marginTop: "1%",
                  overflowY: "scroll",
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgba(50, 144, 236, 0.05)",
                    borderRadius: 5,
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#3290EC",
                    }}
                  >
                    {`W${truckData?.width} x L${truckData?.length} x H${truckData?.height}`}
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 500,
                      marginTop: 5,
                      color: "rgba(0, 0, 0, 1)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Dimensions (in)
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "rgba(50, 144, 236, 0.05)",
                    borderRadius: 5,
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#3290EC",
                    }}
                  >
                    {pageData?.truck_size}
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 500,
                      marginTop: 5,
                      color: "rgba(0, 0, 0, 1)",
                    }}
                  >
                    Sug.Truck Size
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "rgba(50, 144, 236, 0.05)",
                    borderRadius: 5,
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      letterSpacing: 0,
                      textAlign: "left",
                      color: "#3290EC",
                    }}
                  >
                    {pageData?.stackable}
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 500,
                      marginTop: 5,
                      color: "rgba(0, 0, 0, 1)",
                    }}
                  >
                    Stackable
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              flex: 0.7,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              gap: "5%",
            }}
          >
            <div
              style={{
                display: "flex",
                paddingInline: 10,
                backgroundColor: "rgba(50, 144, 236, 0.05)",
                borderRadius: 5,
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: "5%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5%",
                  marginBottom: "5%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={driver} style={{ width: 18, height: 18 }} />
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: "0.8rem",
                      marginLeft: 10,
                    }}
                  >
                    {pageData?.driver_fullname}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10%",
                  }}
                >
                  <img src={truck} style={{ width: 18, height: 18 }} />
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: "0.8rem",
                      marginLeft: 10,
                    }}
                  >
                    {truckData?.identifier}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10%",
                  }}
                >
                  <img src={id} style={{ width: 18, height: 18 }} />
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: "0.8rem",
                      marginLeft: 10,
                    }}
                  >
                    {pageData?.plate_num}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10%",
                  }}
                >
                  <img src={vin} style={{ width: 18, height: 18 }} />
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: "0.8rem",
                      marginLeft: 10,
                    }}
                  >
                    {pageData?.vin_num}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="pb-notes-content"
              style={{
                backgroundColor: "rgba(245, 249, 254, 1)",
                paddingInline: 20,
                paddingTop: 10,
                paddingBottom: 10,
                flexGrow: 1,
                marginBottom: "5%",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                minHeight: 200,
              }}
            >
              <div
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  letterSpacing: 0,
                  textAlign: "left",
                }}
              >
                Notes:
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  letterSpacing: 0,
                  textAlign: "left",
                  marginTop: 5,
                  flex: 1,
                  flexBasis: 1,
                  width: "100%",
                  overflowY: "scroll",
                }}
              >
                {pageData?.note}
              </div>
            </div>
          </div>
        </div>

        <img src={map} className="nav-icon" onClick={() => switchViews(0)} />
      </div>
    </>
  );
}
