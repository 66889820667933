/** @format */

import L from "leaflet";
import "../../fns/L.Path.DashFlow";
import "leaflet-rotatedmarker";
import { useEffect, useRef } from "react";
import { getVehicleMarker } from "../../fns/vehicleMarker";
import { addRoute } from "../../fns/addRoute";
import { addPickupDelivery } from "../../fns/addPickupDelivery";
import detail from "../../assets/detail.png";

export function LiveMapContainer({ orderInfo, switchViews }) {
  const mapContainer = useRef();
  const mapRef = useRef();

  useEffect(() => {
    let map = L.map(mapContainer.current, {
      attributionControl: false,
      zoomControl: true,
      zoomAnimation: true,
    }).setView(
      [
        orderInfo?.truck?.loc[0] || 40.73307350435217,
        orderInfo?.truck?.loc[1] || -74.00047795614519,
      ],
      mapRef.current?.getZoom() || 5
    );

    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      updateWhenIdle: true,
      updateWhenZooming: true,
      updateInterval: 200,
    }).addTo(map);

    getVehicleMarker(orderInfo, map);

    addRoute(orderInfo, map);

    addPickupDelivery(orderInfo, map);

    if (orderInfo?.truck && orderInfo?.pickup && orderInfo?.delivery) {
      // map.fitBounds(
      //   [orderInfo.truck.loc, orderInfo.pickup.loc, orderInfo.delivery.loc],
      //   {
      //     padding: [100, 100], // Increase padding to 100px on each side
      //   }
      // );

      map.setView(orderInfo.delivery.loc, 10);
    }

    mapRef.current = map;

    return () => map.remove();
  }, [orderInfo]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexBasis: 1,
          width: "100%",
          height: "100%",
          backgroundColor: "blue",
        }}
      >
        <div
          className="view-map"
          ref={(el) => (mapContainer.current = el)}
          onTouchEnd={() => {
            setTimeout(() => {
              try {
                mapRef.current.invalidateSize(true);
              } catch (e) {}
            }, 100);
          }}
        ></div>
        {/* <div style={{padding: 0, margin: 0, width: "100%", height: "75vh",}}
                 ref={el => mapContainer.current = el}>
            </div>*/}

        <img
          src={detail}
          className="nav-icon"
          onClick={() => {
            switchViews(1);
          }}
        />
      </div>
    </>
  );
}
