/** @format */

import L from "leaflet";
import vehicle from "../assets/tr.svg";

export function getVehicleMarker(orderData, map) {
  const title = orderData?.truck?.identifier ? orderData.truck.identifier : "";
  const name = orderData?.truck?.name ? orderData.truck.name : "";
  const address = orderData?.truck?.address ? orderData.truck.address : "";
  const distance = orderData?.truck?.path?.distance
    ? orderData.truck.path.distance
    : "";
  const total = orderData?.route?.distance ? orderData.route.distance : "";
  const truckDegrees = orderData?.truck?.path?.bearing
    ? orderData.truck.path.bearing
    : "";

  const iconOptions = {
    iconUrl: vehicle,
    iconSize: [50, 50],
    popupAnchor: [0, -25],
    shadowSize: [68, 55],
    shadowAnchor: [22, 55],
  };
  const customIcon = L.icon(iconOptions);

  const markerOptions = {
    icon: customIcon,
    title: title,
    draggable: false,
    rotationAngle: truckDegrees,
    rotationOrigin: "center center",
  };

  let trInfo = `<b>${title}</b><br>Driver: ${name}<br>${address}<br>Out Miles; ${distance}<br>Total Miles: ${total}`;

  const truck = new L.marker(
    orderData?.truck?.loc ? orderData.truck.loc : [0, 0],
    markerOptions
  ).addTo(map);

  truck.bindPopup(trInfo);

  return map;
}
