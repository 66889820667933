/** @format */

import L from "leaflet";
import "polyline-encoded";

export function addRoute(orderData, map) {
  const geom = orderData?.route?.geometry ? orderData.route.geometry : "";

  const routeFrom = L.Polyline.fromEncoded(geom);
  const latlngFrom = routeFrom.getLatLngs();

  routeFrom.setStyle({
    color: "#3290EC",
    weight: 5,
  });

  const dashFrom = L.polyline(latlngFrom, {
    dashArray: "15,15",
    dashSpeed: -20,
  });

  dashFrom.setStyle({
    color: "white",
    weight: 2,
    dashArray: "2 3",
  });

  routeFrom.addTo(map);
  map.addLayer(dashFrom);
  const routeTo = L.Polyline.fromEncoded(
    orderData?.truck?.path?.geometry ? orderData.truck.path.geometry : ""
  );
  const latlngTo = routeTo.getLatLngs();

  routeTo.setStyle({
    color: "#8A8A8A",
    weight: 5,
  });
  const dashTo = L.polyline(latlngTo, {
    dashArray: "15,15",
    dashSpeed: -20,
  });

  dashTo.setStyle({
    color: "white",
    weight: 2,
    dashArray: "2 3",
  });

  routeTo.addTo(map);
  map.addLayer(dashTo);
  return map;
}
