/** @format */

import { useEffect } from "react";
import { useTelegram } from "./fns/useTelegram";
import { AppWrapper } from "./Components/AppWrapper";

function App() {
  const { expand, tg } = useTelegram();

  useEffect(() => {
    expand();
  }, []);

  return <AppWrapper />;
}

export default App;
